<template>
  <div class="container">
    <header />
    <p>
      Privacy Policy of <strong>Muccanado Applications</strong><br />
      This Application collects some Personal Data from its Users.
    </p>
    <hr class="solid" />
    <p id="owner_of_the_data">
      <strong>Owner and Data Controller</strong><br />
      Muccanado di Roncagalli Alessandro<br />
      Via Fioccarde 10<br />
      Noventa Vicentina<br />
      36025 Vicenza<br />
      (Italy)
    </p>
    <p><strong>Owner contact email:</strong> muccanado@gmail.com</p>
    <hr class="solid" />
    <p id="types_of_data">
      <span style="font-size: 18pt;"
        ><strong>Types of Data collected</strong></span
      ><br />
      Among the types of Personal Data that this Application collects, by itself
      or through third parties, there are: Cookies, Usage Data, unique device
      identifiers for advertising (Google Advertiser ID or IDFA, for example),
      various types of Data, Contacts permission, Camera permission, Precise
      location permission (continuous), Precise location permission
      (non-continuous), Approximate location permission (continuous),
      Approximate location permission (non-continuous), Microphone permission,
      Phone permission, SMS permission, Storage permission, Reminders
      permission, Bluetooth sharing permission, phone number and geographic
      position.<br />
      Complete details on each type of Personal Data collected are provided in
      the dedicated sections of this privacy policy or by specific explanation
      texts displayed prior to the Data collection.<br />
      Personal Data may be freely provided by the User, or, in case of Usage
      Data, collected automatically when using this Application.<br />
      Unless specified otherwise, all Data requested by this Application is
      mandatory and failure to provide this Data may make it impossible for this
      Application to provide its services. In cases where this Application
      specifically states that some Data is not mandatory, Users are free not to
      communicate this Data without consequences to the availability or the
      functioning of the Service.<br />
      Users who are uncertain about which Personal Data is mandatory are welcome
      to contact the Owner.<br />
      Any use of Cookies – or of other tracking tools – by this Application or
      by the owners of third-party services used by this Application serves the
      purpose of providing the Service required by the User, in addition to any
      other purposes described in the present document and in the Cookie Policy,
      if available.<br />
      Users are responsible for any third-party Personal Data obtained,
      published or shared through this Application and confirm that they have
      the third party’s consent to provide the Data to the Owner.
    </p>
    <hr class="solid" />
    <div class="wpb_wrapper">
      <p>
        <span style="font-size: 18pt;"
          ><strong>Mode and place of processing the Data</strong></span
        >
      </p>
      <p>
        <strong>Methods of processing</strong><br />
        The Owner takes appropriate security measures to prevent unauthorized
        access, disclosure, modification, or unauthorized destruction of the
        Data.<br />
        The Data processing is carried out using computers and/or IT enabled
        tools, following organizational procedures and modes strictly related to
        the purposes indicated. In addition to the Owner, in some cases, the
        Data may be accessible to certain types of persons in charge, involved
        with the operation of this Application (administration, sales,
        marketing, legal, system administration) or external parties (such as
        third-party technical service providers, mail carriers, hosting
        providers, IT companies, communications agencies) appointed, if
        necessary, as Data Processors by the Owner. The updated list of these
        parties may be requested from the Owner at any time.
      </p>
      <p>
        <strong>Legal basis of processing</strong><br />
        The Owner may process Personal Data relating to Users if one of the
        following applies:
      </p>
      <p style="padding-left: 30px;">
        -Users have given their consent for one or more specific purposes. Note:
        Under some legislations the Owner may be allowed to process Personal
        Data until the User objects to such processing (“opt-out”), without
        having to rely on consent or any other of the following legal bases.
        This, however, does not apply, whenever the processing of Personal Data
        is subject to European data protection law;<br />
        -provision of Data is necessary for the performance of an agreement with
        the User and/or for any pre-contractual obligations thereof;<br />
        -processing is necessary for compliance with a legal obligation to which
        the Owner is subject;<br />
        -processing is related to a task that is carried out in the public
        interest or in the exercise of official authority vested in the
        Owner;<br />
        -processing is necessary for the purposes of the legitimate interests
        pursued by the Owner or by a third party.
      </p>
      <p>
        In any case, the Owner will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </p>
      <p>
        <strong>Place</strong><br />
        The Data is processed at the Owner’s operating offices and in any other
        places where the parties involved in the processing are located.
      </p>
      <p>
        Depending on the User’s location, data transfers may involve
        transferring the User’s Data to a country other than their own. To find
        out more about the place of processing of such transferred Data, Users
        can check the section containing details about the processing of
        Personal Data.<br />
        Users are also entitled to learn about the legal basis of Data transfers
        to a country outside the European Union or to any international
        organization governed by public international law or set up by two or
        more countries, such as the UN, and about the security measures taken by
        the Owner to safeguard their Data.
      </p>
      <p>
        If any such transfer takes place, Users can find out more by checking
        the relevant sections of this document or inquire with the Owner using
        the information provided in the contact section.
      </p>
      <p>
        <strong>Retention time</strong><br />
        Personal Data shall be processed and stored for as long as required by
        the purpose they have been collected for.<br />
        Therefore:
      </p>
      <p style="padding-left: 30px;">
        -Personal Data collected for purposes related to the performance of a
        contract between the Owner and the User shall be retained until such
        contract has been fully performed.<br />
        -Personal Data collected for the purposes of the Owner’s legitimate
        interests shall be retained as long as needed to fulfill such purposes.
        Users may find specific information regarding the legitimate interests
        pursued by the Owner within the relevant sections of this document or by
        contacting the Owner.
      </p>
      <p>
        The Owner may be allowed to retain Personal Data for a longer period
        whenever the User has given consent to such processing, as long as such
        consent is not withdrawn. Furthermore, the Owner may be obliged to
        retain Personal Data for a longer period whenever required to do so for
        the performance of a legal obligation or upon order of an authority.
      </p>
      <p>
        Once the retention period expires, Personal Data shall be
        deleted.Therefore, the right to access, the right to erasure, the right
        to rectification and the right to data portability cannot be enforced
        after expiration of the retention period.
      </p>
    </div>
    <hr class="solid" />
    <p id="use_collected_data">
      <span style="font-size: 18pt;"
        ><strong>The purposes of processing</strong></span
      ><br />
      The Data concerning the User is collected to allow the Owner to provide
      its Services, as well as for the following purposes: Access to third-party
      accounts, Advertising, Analytics, Backup saving and management, Content
      performance and features testing (A/B testing), Data transfer outside the
      EU, Device permissions for Personal Data access, Displaying content from
      external platforms, Handling payments, Hosting and backend infrastructure,
      Infrastructure monitoring, Location-based interactions, Remarketing and
      behavioral targeting, Social features and SPAM protection.<br />
      Users can find further detailed information about such purposes of
      processing and about the specific Personal Data used for each purpose in
      the respective sections of this document.
    </p>
    <hr class="solid" />
    <div class="wpb_wrapper">
      <p>
        <span style="font-size: 18pt;"
          ><strong>Device permissions for Personal Data access</strong></span
        ><br />
        Depending on the User’s specific device, this Application may request
        certain permissions that allow it to access the User’s device Data as
        described below.<br />
        By default, these permissions must be granted by the User before the
        respective information can be accessed. Once the permission has been
        given, it can be revoked by the User at any time. In order to revoke
        these permissions, Users may refer to the device settings or contact the
        Owner for support at the contact details provided in the present
        document.<br />
        The exact procedure for controlling app permissions may be dependent on
        the User’s device and software.<br />
        Please note that the revoking of such permissions might impact the
        proper functioning of this Application.<br />
        If User grants any of the permissions listed below, the respective
        Personal Data may be processed (i.e accessed to, modified or removed) by
        this Application.
      </p>
      <p>
        <strong>Approximate location permission (continuous)</strong><br />
        Used for accessing the User’s approximate device location. This
        Application may collect, use, and share User location Data in order to
        provide location-based services.<br />
        <strong>Approximate location permission (non-continuous)</strong><br />
        Used for accessing the User’s approximate device location. This
        Application may collect, use, and share User location Data in order to
        provide location-based services.<br />
        The geographic location of the User is determined in a manner that isn’t
        continuous. This means that it is impossible for this Application to
        derive the approximate position of the User on a continuous basis.
      </p>
      <p>
        <strong>Bluetooth sharing permission</strong><br />
        Used for accessing Bluetooth related functions such as scanning for
        devices, connecting with devices, and allowing data transfer between
        devices.
      </p>
      <p>
        <strong>Camera permission</strong><br />
        Used for accessing the camera or capturing images and video from the
        device.
      </p>
      <p>
        <strong>Contacts permission</strong><br />
        Used for accessing contacts and profiles on the User’s device, including
        the changing of entries.
      </p>
      <p>
        <strong>Microphone permission</strong><br />
        Used for accessing and recording microphone audio from the User’s
        device.
      </p>
      <p>
        <strong>Phone permission</strong><br />
        Used for accessing a host of typical features associated with telephony.
        This enables, for instance, read-only access to the “phone state”, which
        means it enables access to the phone number of the device, current
        mobile network information, or the status of any ongoing calls.
      </p>
      <p>
        <strong>Precise location permission (continuous)</strong><br />
        Used for accessing the User’s precise device location. This Application
        may collect, use, and share User location Data in order to provide
        location-based services.
      </p>
      <p>
        <strong>Precise location permission (non-continuous)</strong><br />
        Used for accessing the User’s precise device location. This Application
        may collect, use, and share User location Data in order to provide
        location-based services.<br />
        The geographic location of the User is determined in a manner that isn’t
        continuous. This means that it is impossible for this Application to
        derive the exact position of the User on a continuous basis.
      </p>
      <p>
        <strong>Reminders permission</strong><br />
        Used for accessing the Reminders app on the User’s device, including the
        reading, adding and removing of entries.
      </p>
      <p>
        <strong>SMS permission</strong><br />
        Used for accessing features related to the User’s messaging including
        the sending, receiving and reading of SMS.
      </p>
      <p>
        <strong>Storage permission</strong><br />
        Used for accessing shared external storage, including the reading and
        adding of any items.
      </p>
    </div>
    <hr class="solid" />
    <div class="wpb_wrapper">
      <p>
        <span style="font-size: 18pt;"
          ><strong
            >Detailed information on the processing of Personal Data</strong
          ></span
        ><br />
        Personal Data is collected for the following purposes and using the
        following services:
      </p>
      <p>
        <strong>Access to third-party accounts</strong><br />
        This type of service allows this Application to access Data from your
        account on a third-party service and perform actions with it.<br />
        These services are not activated automatically, but require explicit
        authorization by the User.
      </p>
      <p>
        <strong>Dropbox account access (Dropbox, Inc.)</strong><br />
        This service allows this Application to connect with the User’s account
        on Dropbox, provided by Dropbox, Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.dropbox.com/privacy">Privacy Policy</a>. Privacy
        Shield participant.
      </p>
      <p>
        <strong>Google Drive account access (Google Inc.)</strong><br />
        This service allows this Application to connect with the User’s account
        on Google Drive, provided by Google, Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://support.google.com/drive/answer/2450387?hl=en"
          >Privacy Policy</a
        >. Privacy Shield participant.
      </p>
      <hr />
      <p>
        <strong>Advertising</strong><br />
        This type of service allows User Data to be utilized for advertising
        communication purposes displayed in the form of banners and other
        advertisements on this Application, possibly based on User interests.<br />
        This does not mean that all Personal Data are used for this purpose.
        Information and conditions of use are shown below.<br />
        Some of the services listed below may use Cookies to identify Users or
        they may use the behavioral retargeting technique, i.e. displaying ads
        tailored to the User’s interests and behavior, including those detected
        outside this Application. For more information, please check the privacy
        policies of the relevant services.<br />
        In addition to any opt out offered by any of the services below, the
        User may opt out of a third-party service’s use of cookies by visiting
        the
        <a href="http://optout.networkadvertising.org/"
          >Network Advertising Initiative opt-out page</a
        >.
      </p>
      <p>
        <strong>Adcash (Adcash OÜ)</strong><br />
        Adcash is an advertising service provided by Adcash OÜ.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Estonia –
        <a href="https://www.adcash.com/legal-info/#h.r05qsnxjz0g8"
          >Privacy Policy</a
        >.
      </p>
      <p>
        <strong>AdColony (Jirbo, Inc.)</strong><br />
        AdColony is an advertising service provided by Jirbo, Inc.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="http://www.adcolony.com/privacy-policy/">Privacy Policy</a> –
        <a href="http://www.adcolony.com/privacy-policy/opt-out">Opt out</a>.
      </p>
      <p>
        <strong>Adform (Adform)</strong><br />
        Adform is an advertising service provided by Adform.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Denmark –
        <a href="http://www.adform.com/site/privacy-policy/">Privacy Policy</a>.
      </p>
      <p>
        <strong>AdMob (AdMob Google Inc.)</strong><br />
        AdMob is an advertising service provided by AdMob Google Inc.<br />
        In order to understand Google’s use of Data, consult
        <a href="https://www.google.com/policies/privacy/partners/"
          >Google’s partner policy</a
        >.<br />
        Personal Data collected: Cookies, unique device identifiers for
        advertising (Google Advertiser ID or IDFA, for example) and Usage
        Data.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/technologies/ads/"
          >Privacy Policy</a
        >
        – <a href="https://www.google.com/settings/ads">Opt Out</a>. Privacy
        Shield participant.
      </p>
      <p>
        <strong>Amazon Mobile Ads (Amazon)</strong><br />
        Amazon Mobile Ads is an advertising service provided by Amazon.com,
        Inc.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a
          href="https://www.amazon.com/gp/help/customer/display.html/ref=UTF8&amp;nodeId=468496"
          >Privacy Policy</a
        >.
      </p>
      <p>
        <strong>Apple iAd (Apple Inc.)</strong><br />
        Apple iAd is an advertising service provided by Apple Inc.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.apple.com/privacy/privacy-policy/"
          >Privacy Policy</a
        >
        – <a href="http://oo.apple.com/">Opt out</a>.
      </p>
      <p>
        <strong>Applifier (Applifier Ltd.)</strong><br />
        Applifier is an advertising service provided by Applifier Ltd.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Finland –
        <a href="https://unity3d.com/legal/privacy-policy">Privacy Policy</a> –
        <a href="https://secure.applifier.com/opt_out.html">Opt out</a>.
      </p>
      <p>
        <strong>Appnext (Appnext Ltd.)</strong><br />
        Appnext is an advertising service provided by Appnext Ltd.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Virgin Islands, British –
        <a href="https://www.appnext.com/policy.html">Privacy Policy</a>.
      </p>
      <p>
        <strong>Bing Ads (Microsoft Corporation)</strong><br />
        Bing Ads is an advertising service provided by Microsoft Corporation.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://privacy.microsoft.com/privacystatement/"
          >Privacy Policy</a
        >
        – <a href="https://choice.microsoft.com/opt-out">Opt Out</a>.
      </p>
      <p>
        <strong>Chartboost (Chartboost, Inc.)</strong><br />
        Chartboost is an advertising service provided by Chartboost Inc.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://help.chartboost.com/legal/privacy">Privacy Policy</a>.
      </p>
      <p>
        <strong>Epom Ad Server (Epom Ltd.)</strong><br />
        Epom Ad Server is an advertising service provided by Epom Ltd.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Dominica –
        <a href="https://epom.com/privacy-policy">Privacy Policy</a>.
      </p>
      <p>
        <strong>Facebook Audience Network (Facebook, Inc.)</strong><br />
        Facebook Audience Network is an advertising service provided by
        Facebook, Inc. In order to understand Facebook’s use of Data, consult
        <a href="https://www.facebook.com/about/privacy/"
          >Facebook’s data policy</a
        >. This Application may use identifiers for mobile devices (including
        Android Advertising ID or Advertising Identifier for iOS, respectively)
        and technologies similar to cookies to run the Facebook Audience Network
        service. One of the ways Audience Network shows ads is by using the
        User’s ad preferences. The User can control this in the
        <a href="https://www.facebook.com/ads/settings">Facebook ad settings</a
        >. Users may opt-out of certain Audience Network targeting through
        applicable device settings, such as the device advertising settings for
        mobile phones or by following the instructions in other Audience Network
        related sections of this privacy policy, if available.<br />
        Personal Data collected: Cookies, unique device identifiers for
        advertising (Google Advertiser ID or IDFA, for example) and Usage
        Data.<br />
        Place of processing: United States –
        <a href="https://www.facebook.com/about/privacy/">Privacy Policy</a> –
        <a href="https://www.facebook.com/settings?tab=ads">Opt Out</a>. Privacy
        Shield participant.
      </p>
      <p>
        <strong>Clickpoint (CLICKPOINT MARKETING SLU.)</strong><br />
        Clickpoint is an advertising service provided by CLICKPOINT MARKETING
        SLU.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Spain –
        <a href="http://site.clickpoint.com/privacy/">Privacy Policy</a> –
        <a href="http://site.clickpoint.com/privacy-opt-out/">Opt Out</a>.
      </p>
      <p>
        <strong>Flurry RTB (Yahoo! Inc.)</strong><br />
        Flurry RTB (Flurry Real-Time Bidding) is an advertising service provided
        by Yahoo! Inc. that allows the Owner to run advertising campaigns in
        conjunction with external advertising networks that the Owner, unless
        otherwise specified in this document, has no direct relationship
        with.<br />
        This Application is collecting and transmitting Data from the User
        through the Flurry RTB service to media buyers bidding to purchase its
        inventory. These buyers may collect and store such Data and use such
        Data for retargeting or other advertising purposes.<br />
        If the User chooses to opt-out, Flurry will stop tracking Data for the
        device identified by the provided MAC address and/or device identifier
        going forward. Flurry will also stop providing ads and/or
        recommendations to that MAC address and/or device identifier across all
        publishers.<br />
        In order to opt out from being tracked by various advertising networks,
        Users may make use of
        <a href="http://www.youronlinechoices.com/">Youronlinechoices</a>.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://info.yahoo.com/privacy/">Privacy Policy</a> –
        <a href="https://developer.yahoo.com/flurry/end-user-opt-out/"
          >Opt Out</a
        >.
      </p>
      <p>
        <strong>Fyber (SponsorPay GmbH)</strong><br />
        Fyber is an advertising service provided by Fyber GmbH.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Germany –
        <a href="https://www.fyber.com/privacypolicy-en.html">Privacy Policy</a
        >.
      </p>
      <p>
        <strong>Google AdSense (Google Inc.)</strong><br />
        Google AdSense is an advertising service provided by Google Inc. This
        service uses the “Doubleclick” Cookie, which tracks use of this
        Application and User behavior concerning ads, products and services
        offered.<br />
        Users may decide to disable all the Doubleclick Cookies by clicking on:
        <a href="https://www.google.com/settings/ads/onweb/optout"
          >google.com/settings/ads/onweb/optout</a
        >.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/technologies/ads/"
          >Privacy Policy</a
        >
        –
        <a href="https://www.google.com/settings/ads/onweb/optout">Opt Out</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>LeadBolt (LeadBolt Pty Limited)</strong><br />
        LeadBolt is an advertising service provided by LeadBolt Pty Limited.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.leadbolt.com/privacy/">Privacy Policy</a>.
      </p>
      <p>
        <strong>MoPub (MoPub Inc.)</strong><br />
        MoPub is an advertising service provided by MoPub Inc.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.mopub.com/legal/privacy/">Privacy Policy</a>.
      </p>
      <p>
        <strong>MobFox (MobFox Mobile Advertising GmbH)</strong><br />
        MobFox is an advertising service provided by MobFox Mobile Advertising
        GmbH.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Austria –
        <a href="http://www.mobfox.com/privacy-policy/">Privacy Policy</a>.
      </p>
      <p>
        <strong>StartApp (StartApp, Inc.)</strong><br />
        StartApp is an advertising service provided by StartApp, Inc.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="http://www.searchmobileonline.com/static/eula_more_sdk.html"
          >Privacy Policy</a
        >.
      </p>
      <hr />
      <p>
        <strong>Analytics</strong><br />
        The services contained in this section enable the Owner to monitor and
        analyze web traffic and can be used to keep track of User behavior.
      </p>
      <p>
        <strong>Adobe Analytics (Adobe Systems Incorporated)</strong><br />
        Adobe Analytics is an analytics service provided by Adobe Systems,
        Inc.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.adobe.com/privacy.html">Privacy Policy</a> –
        <a href="https://www.adobe.com/privacy/opt-out.html">Opt Out</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Analytics collected directly (this Application)</strong><br />
        This Application uses an internal analytics system that does not involve
        third parties.<br />
        Personal Data collected: Cookies and Usage Data.
      </p>
      <p>
        <strong>Appsflyer (AppsFlyer Ltd)</strong><br />
        Appsflyer is an analytics service provided by AppsFlyer Ltd.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Israel –
        <a href="https://www.appsflyer.com/privacy-policy/">Privacy Policy</a> –
        <a href="mailto:%20privacy@appsflyer.com">Opt Out</a>.
      </p>
      <p>
        <strong
          >Fabric Answers: Twitter Enhanced Features extension (Twitter,
          Inc.)</strong
        ><br />
        Fabric Answers allows the Owner to share data with Twitter in order to
        enable conversion tracking, interest-based advertising, and
        personalization of other products and services for individual Users or
        devices.<br />
        In order for the described services to work, third parties such as
        Twitter collect and use information about Users’ actions, including
        browsing and shopping activities. Users may find instructions for
        opt-out described more generally under the section Fabric Answers or by
        contacting the Owner directly.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://twitter.com/en/privacy">Privacy Policy</a>.
      </p>
      <p>
        <strong>Fabric Answers (Google Inc.)</strong><br />
        Fabric Answers is an analytics service provided by Crashlytics, a
        business division of Google Inc.<br />
        In order to understand Google’s use of Data, consult
        <a href="https://www.google.com/policies/privacy/partners/"
          >Google’s partner policy</a
        >. This service is designed for mobile apps analytics and can collect
        various information about your phone, highlighted in the Fabric Answers
        privacy policy.<br />
        In particular, this Application uses identifiers for mobile devices
        (including Android Advertising ID or Advertising Identifier for iOS,
        respectively) and technologies similar to cookies to run the Fabric
        Answers service. Fabric Answers may share Data with other tools provided
        by Fabric/Crashlytics, such as Crashlytics or Twitter. The User may
        check this privacy policy to find a detailed explanation about the other
        tools used by the Owner.<br />
        Users may opt-out of certain Fabric Answers features through applicable
        device settings, such as the device advertising settings for mobile
        phones or by following the instructions in other Fabric related sections
        of this privacy policy, if available.<br />
        Personal Data collected: Cookies, unique device identifiers for
        advertising (Google Advertiser ID or IDFA, for example) and Usage
        Data.<br />
        Place of processing: United States –
        <a href="https://answers.io/img/onepager/privacy.pdf">Privacy Policy</a
        >.
      </p>
      <p>
        <strong>Facebook Ads conversion tracking (Facebook, Inc.)</strong><br />
        Facebook Ads conversion tracking is an analytics service provided by
        Facebook, Inc. that connects data from the Facebook advertising network
        with actions performed on this Application.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.facebook.com/about/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Facebook Analytics for Apps (Facebook, Inc.)</strong><br />
        Facebook Analytics for Apps is an analytics service provided by
        Facebook, Inc.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.facebook.com/about/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Flurry Analytics (Flurry, Inc.)</strong><br />
        Flurry Analytics is an analytics service provided by Yahoo! Inc.<br />
        This service is designed for mobile apps analytics and can collect
        various information about your phone, highlighted in the Flurry
        Analytics privacy policy.<br />
        If the User chooses to opt-out, Flurry will stop tracking data for the
        device identified by the provided MAC address and/or device identifier
        going forward. The analytics service tracking will stop across all
        applications within the Flurry network.<br />
        Personal Data collected: Cookies, Usage Data and various types of Data
        as specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://info.yahoo.com/privacy/">Privacy Policy</a> –
        <a href="https://developer.yahoo.com/flurry/end-user-opt-out/"
          >Opt Out</a
        >.
      </p>
      <p>
        <strong>Google Ads conversion tracking (Google Inc.)</strong><br />
        Google Ads conversion tracking is an analytics service provided by
        Google Inc. that connects data from the Google Ads advertising network
        with actions performed on this Application.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.google.com/intl/en/policies/privacy/"
          >Privacy Policy</a
        >. Privacy Shield participant.
      </p>
      <p>
        <strong>Google Analytics (Google Inc.)</strong><br />
        Google Analytics is a web analysis service provided by Google Inc.
        (“Google”). Google utilizes the Data collected to track and examine the
        use of this Application, to prepare reports on its activities and share
        them with other Google services.<br />
        Google may use the Data collected to contextualize and personalize the
        ads of its own advertising network.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.google.com/intl/en/policies/privacy/"
          >Privacy Policy</a
        >
        – <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Google Analytics for Firebase (Google Inc.)</strong><br />
        Google Analytics for Firebase or Firebase Analytics is an analytics
        service provided by Google Inc. In order to understand Google’s use of
        Data, consult
        <a href="https://www.google.com/policies/privacy/partners/"
          >Google’s partner policy</a
        >. Firebase Analytics may share Data with other tools provided by
        Firebase, such as Crash Reporting, Authentication, Remote Config or
        Notifications. The User may check this privacy policy to find a detailed
        explanation about the other tools used by the Owner. This Application
        uses identifiers for mobile devices (including Android Advertising ID or
        Advertising Identifier for iOS, respectively) and technologies similar
        to cookies to run the Firebase Analytics service. Users may opt-out of
        certain Firebase features through applicable device settings, such as
        the device advertising settings for mobile phones or by following the
        instructions in other Firebase related sections of this privacy policy,
        if available.<br />
        Personal Data collected: Cookies, unique device identifiers for
        advertising (Google Advertiser ID or IDFA, for example) and Usage
        Data.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Google Analytics with anonymized IP (Google Inc.)</strong><br />
        Google Analytics is a web analysis service provided by Google Inc.
        (“Google”). Google utilizes the Data collected to track and examine the
        use of this Application, to prepare reports on its activities and share
        them with other Google services.<br />
        Google may use the Data collected to contextualize and personalize the
        ads of its own advertising network.<br />
        This integration of Google Analytics anonymizes your IP address. It
        works by shortening Users’ IP addresses within member states of the
        European Union or in other contracting states to the Agreement on the
        European Economic Area. Only in exceptional cases will the complete IP
        address be sent to a Google server and shortened within the US.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.google.com/intl/en/policies/privacy/"
          >Privacy Policy</a
        >
        – <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>LinkedIn conversion tracking (LinkedIn Corporation)</strong
        ><br />
        LinkedIn conversion tracking is an analytics service provided by
        LinkedIn Corporation that connects data from the LinkedIn advertising
        network with actions performed on this Application.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.linkedin.com/legal/privacy-policy"
          >Privacy Policy</a
        >.
      </p>
      <p>
        <strong>Localytics (Char Software, Inc.)</strong><br />
        Localytics is an analytics service provided by Char Software, Inc.<br />
        This service is designed for mobile apps analytics and can collect
        various information about your phone, highlighted in the Localytics
        privacy policy.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.localytics.com/privacy-policy/">Privacy Policy</a>.
      </p>
      <p>
        <strong>Matomo (this Application)</strong><br />
        Matomo is an analytics software used by this Application to analyze data
        directly without the help of third parties.<br />
        Personal Data collected: Cookies and Usage Data.
      </p>
      <p>
        <strong>Twitter Ads conversion tracking (Twitter, Inc.)</strong><br />
        Twitter Ads conversion tracking is an analytics service provided by
        Twitter, Inc. that connects data from the Twitter advertising network
        with actions performed on this Application.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://twitter.com/privacy">Privacy Policy</a>. Privacy Shield
        participant.
      </p>
      <p>
        <strong>Unity Analytics (Unity Technologies ApS)</strong><br />
        Unity Analytics is an analytics service provided by Unity Technologies
        ApS.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: Denmark –
        <a href="https://unity3d.com/legal/privacy-policy">Privacy Policy</a>.
      </p>
      <p>
        <strong>Yandex Metrica (YANDEX, LLC)</strong><br />
        Yandex Metrica is an analytics and heat mapping service provided by
        YANDEX, LLC. Yandex Metrica can be used to display the areas of a page
        where Users most frequently move the mouse or click. This shows where
        the points of interest are.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: Russian Federation –
        <a href="https://legal.yandex.com/confidential/">Privacy Policy</a>.
      </p>
      <p>
        <strong>Yahoo Advertising conversion tracking (Yahoo! Inc.)</strong
        ><br />
        Yahoo Advertising conversion tracking is an analytics service provided
        by Yahoo! Inc. that connects data from the Yahoo advertising network
        with actions performed on this Application.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://policies.yahoo.com/us/en/yahoo/privacy/index.htm"
          >Privacy Policy</a
        >.
      </p>
      <hr />
      <p>
        <strong>Backup saving and management</strong><br />
        This type of service allows the Owner to save and manage backups of this
        Application on external servers managed by the service provider itself.
        The backups may include the source code and content as well as the data
        that the User provides to this Application.
      </p>
      <p>
        <strong>Backup on Dropbox (Dropbox, Inc.)</strong><br />
        Dropbox is a service to save and manage backups provided by Dropbox
        Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.dropbox.com/privacy#privacy">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Backup on Google Drive (Google Inc.)</strong><br />
        Google Drive is a service to save and manage backups provided by Google
        Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://support.google.com/drive/answer/2450387?hl=en"
          >Privacy Policy</a
        >. Privacy Shield participant.
      </p>
      <p>
        <strong>Vaultpress (Automattic Inc.)</strong><br />
        Vaultpress is a service to save and manage backups provided by
        Automattic Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://automattic.com/privacy/">Privacy Policy</a>.
      </p>
      <hr />
      <p>
        <strong>Content performance and features testing (A/B testing)</strong
        ><br />
        The services contained in this section allow the Owner to track and
        analyze the User response concerning web traffic or behavior regarding
        changes to the structure, text or any other component of this
        Application.<br />
        <strong>Firebase Remote Config (Google Inc.)</strong><br />
        Firebase Remote Config is an A/B testing and configuration service
        provided by Google Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <hr />
      <p>
        <strong>Data transfer outside the EU</strong><br />
        The Owner is allowed to transfer Personal Data collected within the EU
        to third countries (i.e. any country not part of the EU) only pursuant
        to a specific legal basis. Any such Data transfer is based on one of the
        legal bases described below.<br />
        Users can inquire with the Owner to learn which legal basis applies to
        which specific service.
      </p>
      <p>
        <strong>Data transfer abroad based on consent (this Application)</strong
        ><br />
        If this is the legal basis, Personal Data of Users shall be transferred
        from the EU to third countries only if the User has explicitly consented
        to such transfer, after having been informed of the possible risks due
        to the absence of an adequacy decision and appropriate safeguards.<br />
        In such cases, the Owner shall inform Users appropriately and collect
        their explicit consent via this Application.<br />
        Personal Data collected: various types of Data.
      </p>
      <p>
        <strong
          >Data transfer abroad based on standard contractual clauses (this
          Application)</strong
        ><br />
        If this is the legal basis, the transfer of Personal Data from the EU to
        third countries is carried out by the Owner according to “standard
        contractual clauses” provided by the European Commission.<br />
        This means that Data recipients have committed to process Personal Data
        in compliance with the data protection standards set forth by EU data
        protection legislation. For further information, Users are requested to
        contact the Owner through the contact details provided in the present
        document.<br />
        Personal Data collected: various types of Data.
      </p>
      <p>
        <strong
          >Data transfer from the EU and/or Switzerland to the U.S based on
          Privacy Shield (this Application)</strong
        ><br />
        If this is the legal basis, the transfer of Personal Data from the EU or
        Switzerland to the US is carried out according to the EU – U.S. and
        Swiss – U.S. Privacy Shield.<br />
        In particular, Personal Data is transferred to services that
        self-certify under the Privacy Shield framework and therefore guarantee
        an adequate level of protection of such transferred Data. All services
        are listed within the relevant section of this document and those that
        adhere to Privacy Shield can be singled out by checking their privacy
        policy and possibly also by specifically checking for Privacy Shield
        adherence in the official Privacy Shield List. Privacy Shield also
        specifically guarantees rights to Users which can be found in its most
        current form on the website run by the US Department of Commerce.<br />
        Personal Data may be transferred from within the EU or Switzerland to
        the U.S. to services that are not, or not anymore, part of Privacy
        Shield, only based on other valid legal grounds. Users can ask the Owner
        to learn about such legal grounds.<br />
        Personal Data collected: various types of Data.
      </p>
      <p>
        <strong
          >Data transfer to countries that guarantee European standards (this
          Application)</strong
        ><br />
        If this is the legal basis, the transfer of Personal Data from the EU to
        third countries is carried out according to an adequacy decision of the
        European Commission.<br />
        The European Commission adopts adequacy decisions for specific countries
        whenever it considers that country to possess and provide Personal Data
        protection standards comparable to those set forth by EU data protection
        legislation. Users can find an updated list of all adequacy decisions
        issued on the European Commission’s website.<br />
        Personal Data collected: various types of Data.
      </p>
      <p>
        <strong
          >Other legal basis for Data transfer abroad (this Application)</strong
        ><br />
        If no other legal basis applies, Personal Data shall be transferred from
        the EU to third countries only if at least one of the following
        conditions is met:
      </p>
      <p style="padding-left: 30px;">
        -the transfer is necessary for the performance of a contract between the
        User and the Owner or of pre-contractual measures taken at the User’s
        request;<br />
        the transfer is necessary for the conclusion or performance of a
        contract concluded in the interest of the User between the Owner and
        another natural or legal person;<br />
        -the transfer is necessary for important reasons of public interest;<br />
        -the transfer is necessary for establishment, exercise or defence of
        legal claims;<br />
        -the transfer is necessary in order to protect the vital interests of
        the data subject or of other persons, where the data subject is
        physically or legally incapable of giving consent. In such cases, the
        Owner shall inform the User about the legal bases the transfer is based
        on via this Application.
      </p>
      <p>Personal Data collected: various types of Data.</p>
      <hr />
      <p>
        <strong>Device permissions for Personal Data access</strong><br />
        This Application requests certain permissions from Users that allow it
        to access the User’s device Data as described below.<br />
        <strong
          >Device permissions for Personal Data access (this
          Application)</strong
        ><br />
        This Application requests certain permissions from Users that allow it
        to access the User’s device Data as summarized here and described within
        this document.<br />
        Personal Data collected: Approximate location permission (continuous),
        Approximate location permission (non-continuous), Bluetooth sharing
        permission, Camera permission, Contacts permission, Microphone
        permission, Phone permission, Precise location permission (continuous),
        Precise location permission (non-continuous), Reminders permission, SMS
        permission and Storage permission.
      </p>
      <hr />
      <p>
        <strong>Displaying content from external platforms</strong><br />
        This type of service allows you to view content hosted on external
        platforms directly from the pages of this Application and interact with
        them.<br />
        This type of service might still collect web traffic data for the pages
        where the service is installed, even when Users do not use it.
      </p>
      <p>
        <strong>Adobe Edge Web Fonts (Adobe Systems Incorporated)</strong><br />
        Adobe Edge Web Fonts is a typeface visualization service provided by
        Adobe Systems Incorporated that allows this Application to incorporate
        content of this kind on its pages.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.adobe.com/privacy.html">Privacy Policy</a>. Privacy
        Shield participant.
      </p>
      <p>
        <strong>Fonts.com Web Fonts (Monotype Imaging Holdings Inc.)</strong
        ><br />
        Fonts.com Web Fonts is a typeface visualization service provided by
        Monotype Imaging Holdings Inc. that allows this Application to
        incorporate content of this kind on its pages.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="http://www.monotype.com/legal/privacy-policy">Privacy Policy</a
        >.
      </p>
      <p>
        <strong>Google Fonts (Google Inc.)</strong><br />
        Google Fonts is a typeface visualization service provided by Google Inc.
        that allows this Application to incorporate content of this kind on its
        pages.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.it/intl/policies/privacy/">Privacy Policy</a
        >. Privacy Shield participant.
      </p>
      <p>
        <strong>Instagram widget (Instagram, Inc.)</strong><br />
        Instagram is an image visualization service provided by Instagram, Inc.
        that allows this Application to incorporate content of this kind on its
        pages.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.instagram.com/about/legal/privacy/"
          >Privacy Policy</a
        >.
      </p>
      <p>
        <strong>Mapbox widget (Mapbox Inc.)</strong><br />
        Mapbox is a maps visualization service provided by Mapbox Inc. that
        allows this Application to incorporate content of this kind on its
        pages.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.mapbox.com/privacy/">Privacy Policy</a>.
      </p>
      <p>
        <strong>MyFonts (MyFonts Inc.)</strong><br />
        MyFonts is a typeface visualization service provided by MyFonts Inc.
        that allows this Application to incorporate content of this kind on its
        pages.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="http://www.myfonts.com/info/legal/#Privacy">Privacy Policy</a>.
      </p>
      <p>
        <strong>SoundCloud widget (SoundCloud Limited)</strong><br />
        Soundcloud is an audio content delivery service provided by SoundCloud
        Limited that allows this Application to incorporate content of this kind
        on its pages.<br />
        Personal Data collected: Usage Data.<br />
        Place of processing: Germany –
        <a href="https://soundcloud.com/pages/privacy">Privacy Policy</a>.
      </p>
      <hr />
      <p>
        <strong>Handling payments</strong><br />
        Payment processing services enable this Application to process payments
        by credit card, bank transfer or other means. To ensure greater
        security, this Application shares only the information necessary to
        execute the transaction with the financial intermediaries handling the
        transaction.<br />
        Some of these services may also enable the sending of timed messages to
        the User, such as emails containing invoices or notifications concerning
        the payment.
      </p>
      <p>
        <strong>Android Pay (Google Inc.)</strong><br />
        Android Pay is a payment service provided by Google Inc., which allows
        Users to make payments using their mobile phones.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
      </p>
      <p>
        <strong>Apple Pay (Apple Inc.)</strong><br />
        Apple Pay is a payment service provided by Apple Inc., which allows
        Users to make payments using their mobile phones.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.apple.com/privacy/privacy-policy/"
          >Privacy Policy</a
        >.
      </p>
      <p>
        <strong>Google Pay (Google Inc.)</strong><br />
        Google Pay is a payment service provided by Google Inc., which allows
        users to make online payments using their Google credentials.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://wallet.google.com/files/privacy.html?hl=en"
          >Privacy Policy</a
        >. Privacy Shield participant.
      </p>
      <p>
        <strong>PayPal (PayPal Inc.)</strong><br />
        PayPal is a payment service provided by PayPal Inc., which allows Users
        to make online payments.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: See the PayPal privacy policy –
        <a href="https://www.paypal.com/privacy">Privacy Policy</a>.
      </p>
      <p>
        <strong>PayPal Carrier Payments (PayPal Inc.)</strong><br />
        PayPal Carrier Payments is a payment service provided by PayPal, Inc.,
        which allows Users to make online payments using their mobile phone
        operator.<br />
        Personal Data collected: phone number and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: See the PayPal privacy policy –
        <a
          href="https://www.paypal.com/cgi-bin/webscr?cmd=p/gen/ua/policy_privacy-outside"
          >Privacy Policy</a
        >.
      </p>
      <p>
        <strong>PayPal Payments Hub (PayPal Inc.)</strong><br />
        PayPal Payments Hub is a payment service provided by PayPal Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: See the PayPal privacy policy –
        <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
          >Privacy Policy</a
        >.
      </p>
      <hr />
      <p>
        <strong>Hosting and backend infrastructure</strong><br />
        This type of service has the purpose of hosting Data and files that
        enable this Application to run and be distributed as well as to provide
        a ready-made infrastructure to run specific features or parts of this
        Application. Some of these services work through geographically
        distributed servers, making it difficult to determine the actual
        location where the Personal Data are stored.
      </p>
      <p>
        <strong>DigitalOcean (DigitalOcean Inc.)</strong><br />
        DigitalOcean is a hosting service provided by DigitalOcean Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.digitalocean.com/legal/privacy/">Privacy Policy</a
        >; Canada –
        <a href="https://www.digitalocean.com/legal/privacy/">Privacy Policy</a
        >; United Kingdom –
        <a href="https://www.digitalocean.com/legal/privacy/">Privacy Policy</a
        >; Netherlands –
        <a href="https://www.digitalocean.com/legal/privacy/">Privacy Policy</a
        >; Germany –
        <a href="https://www.digitalocean.com/legal/privacy/">Privacy Policy</a
        >; India –
        <a href="https://www.digitalocean.com/legal/privacy/">Privacy Policy</a
        >; Singapore –
        <a href="https://www.digitalocean.com/legal/privacy/">Privacy Policy</a
        >. Privacy Shield participant.
      </p>
      <p>
        <strong>Firebase Cloud Firestore (Google Inc.)</strong><br />
        Firebase Cloud Firestore is a hosting and backend service provided by
        Google Inc.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://firebase.google.com/">Privacy Policy</a>. Privacy
        Shield participant.
      </p>
      <p>
        <strong>Firebase Cloud Functions (Google Inc.)</strong><br />
        Firebase Cloud Functions is a hosting and backend service provided by
        Google Inc.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/intl/en/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Firebase Cloud Storage (Google Inc.)</strong><br />
        Firebase Cloud Storage is a hosting service provided by Google Inc.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Firebase Legacy (Firebase, Inc.)</strong><br />
        Firebase is a hosting and backend service provided by Google Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Firebase Realtime Database (Google Inc.)</strong><br />
        Firebase Realtime Database is a hosting and backend service provided by
        Google Inc.<br />
        Personal Data collected: Usage Data and various types of Data as
        specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/intl/en/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Firebase Hosting (Google Inc.)</strong><br />
        Firebase Hosting is a hosting service provided by Google Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.<br />
        <strong>Google App Engine (Google Inc.)</strong><br />
        Google App Engine is a hosting service provided by Google Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Canada –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Brazil –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Belgium –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; United Kingdom –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Germany –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Japan –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; India –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Australia –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >. Privacy Shield participant.
      </p>
      <p>
        <strong>Google Cloud Storage (Google Inc.)</strong><br />
        Google Cloud Storage is a hosting service provided by Google Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Canada –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Brazil –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Belgium –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; United Kingdom –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Germany –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Netherlands –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Taiwan –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Japan –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; India –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Singapore –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >; Australia –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >. Privacy Shield participant.
      </p>
      <hr />
      <p>
        <strong>Infrastructure monitoring</strong><br />
        This type of service allows this Application to monitor the use and
        behavior of its components so its performance, operation, maintenance
        and troubleshooting can be improved.<br />
        Which Personal Data are processed depends on the characteristics and
        mode of implementation of these services, whose function is to filter
        the activities of this Application.
      </p>
      <p>
        <strong>Crashlytics (Google Inc.)</strong><br />
        Crashlytics is a monitoring service provided by Google Inc.<br />
        Personal Data collected: geographic position, unique device identifiers
        for advertising (Google Advertiser ID or IDFA, for example) and various
        types of Data as specified in the privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://try.crashlytics.com/terms/privacy-policy.pdf"
          >Privacy Policy</a
        >.
      </p>
      <p>
        <strong>Firebase Crash Reporting (Google Inc.)</strong><br />
        Firebase Crash Reporting is a monitoring service provided by Google,
        Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Firebase Performance Monitoring (Google Inc.)</strong><br />
        Firebase Performance Monitoring is a monitoring service provided by
        Google, Inc.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <hr />
      <p>
        <strong>Location-based interactions</strong><br />
        <strong>Geolocation (this Application)</strong><br />
        This Application may collect, use, and share User location Data in order
        to provide location-based services.<br />
        Most browsers and devices provide tools to opt out from this feature by
        default. If explicit authorization has been provided, the User’s
        location data may be tracked by this Application.<br />
        Personal Data collected: geographic position.<br />
        <strong>Non-continuous geolocation (this Application)</strong><br />
        This Application may collect, use, and share User location Data in order
        to provide location-based services.<br />
        Most browsers and devices provide tools to opt out from this feature by
        default. If explicit authorization has been provided, the User’s
        location data may be tracked by this Application.<br />
        The geographic location of the User is determined in a manner that isn’t
        continuous, either at the specific request of the User or when the User
        doesn’t point out its current location in the appropriate field and
        allows the application to detect the position automatically.<br />
        Personal Data collected: geographic position.
      </p>
      <hr />
      <p>
        <strong>Remarketing and behavioral targeting</strong><br />
        This type of service allows this Application and its partners to inform,
        optimize and serve advertising based on past use of this Application by
        the User.<br />
        This activity is performed by tracking Usage Data and by using Cookies,
        information that is transferred to the partners that manage the
        remarketing and behavioral targeting activity.<br />
        In addition to any opt out offered by any of the services below, the
        User may opt out of a third-party service’s use of cookies by visiting
        the
        <a href="http://optout.networkadvertising.org/"
          >Network Advertising Initiative opt-out page</a
        >.
      </p>
      <p>
        <strong>AdWords Remarketing (Google Inc.)</strong><br />
        AdWords Remarketing is a remarketing and behavioral targeting service
        provided by Google Inc. that connects the activity of this Application
        with the Adwords advertising network and the Doubleclick Cookie.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="http://www.google.com/intl/en/policies/privacy/"
          >Privacy Policy</a
        >
        –
        <a href="https://www.google.com/settings/ads/onweb/optout">Opt Out</a>.
      </p>
      <hr />
      <p>
        <strong>Social features</strong><br />
        <strong>Firebase Dynamic Links (Google Inc.)</strong><br />
        Firebase Dynamic Links is a social feature provided by Google Inc.
        Dynamic Links are tracked within Firebase or Google Analytics for
        Firebase, which informs the Owner about the details of the User journey
        to and within this Application.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <p>
        <strong>Firebase Invites (Google Inc.)</strong><br />
        Firebase Invites is a social feature provided by Google Inc. that
        enables Users to share this Application. The sharing may contain
        referral codes, or content from within this Application and may be done
        via email or SMS. Sharing is tracked with Google Analytics for Firebase,
        which informs the Owner that the User has opened or installed this
        Application via invite.<br />
        Personal Data collected: various types of Data as specified in the
        privacy policy of the service.<br />
        Place of processing: United States –
        <a href="https://www.google.com/policies/privacy/">Privacy Policy</a>.
        Privacy Shield participant.
      </p>
      <hr />
      <p>
        <strong>SPAM protection</strong><br />
        This type of service analyzes the traffic of this Application,
        potentially containing Users’ Personal Data, with the purpose of
        filtering it from parts of traffic, messages and content that are
        recognized as SPAM.<br />
        <strong>Google reCAPTCHA (Google Inc.)</strong><br />
        Google reCAPTCHA is a SPAM protection service provided by Google Inc.
      </p>
      <p>
        The use of reCAPTCHA is subject to the Google
        <a href="https://www.google.com/policies/privacy/">privacy policy</a>
        and
        <a href="https://www.google.com/intl/en/policies/terms/">terms of use</a
        >.<br />
        Personal Data collected: Cookies and Usage Data.<br />
        Place of processing: United States –
        <a href="https://www.google.com/intl/policies/privacy/"
          >Privacy Policy</a
        >. Privacy Shield participant.
      </p>
    </div>
    <hr class="solid" />
    <div class="wpb_wrapper">
      <p>
        <span style="font-size: 18pt;"
          ><strong>Further information about Personal Data</strong></span
        >
      </p>
      <p>
        <strong>CloudConvert</strong><br />
        We may use Cloudconvert API in order to convert documents.<br />
        You can see CloudConvert policies in the next link
        <a href="https://cloudconvert.com/privacy"
          >Cloud Convert Privacy Policy</a
        >
      </p>
      <p>
        <strong>Zamzar</strong><br />
        We may use Zamzar API in order to convert documents.<br />
        You can see Zamzar policies in the next link
        <a href="https://developers.zamzar.com/privacy"
          >Zamzar Privacy Policy</a
        >
      </p>
      <p>
        <strong>I Love PDF</strong><br />
        We may use I Love PDF API in order to edit PDFs.<br />
        You can see I Love PDF policies in the next link
        <a href="https://developer.ilovepdf.com/policy"
          >I Love PDF Privacy Policy</a
        >
      </p>
      <p>
        <strong>PhotoeditorSDK</strong><br />
        We may use PhotoeditorSDK in order to edit images.<br />
        You can see more info about PhotoeditorSDK in the next link
        <a href="https://www.photoeditorsdk.com/tos">PhotoeditorSDK TOS</a>
      </p>
      <p>
        <strong>Manage your personal data</strong><br />
        If you want to manage your personal data please contact us via
        privacy@muccanado.com
      </p>
      <p>
        <strong>Push notifications</strong><br />
        This Application may send push notifications to the User.
      </p>
      <p>
        <strong>Access the address book</strong><br />
        This Application may request access to your address book.
      </p>
      <p>
        <strong
          >Analysis and predictions based on the User’s Data
          (“profiling”)</strong
        ><br />
        The Owner may use the Personal and Usage Data collected through this
        Application to create or update User profiles. This type of Data
        processing allows the Owner to evaluate User choices, preferences and
        behaviour for the purposes outlined in the respective section of this
        document.<br />
        User profiles can also be created through the use of automated tools
        like algorithms, which can also be provided by third parties. To find
        out more, about the profiling activities performed, Users can check the
        relevant sections of this document.<br />
        The User always has a right to object to this kind of profiling
        activity. To find out more about the User’s rights and how to exercise
        them, the User is invited to consult the section of this document
        outlining the rights of the User.
      </p>
      <p>
        <strong>Automated decision-making</strong><br />
        Automated decision-making means that a decision which is likely to have
        legal effects or similarly significant effects on the User, is taken
        solely by technological means, without any human intervention. This
        Application may use the User’s Personal Data to make decisions entirely
        or partially based on automated processes according to the purposes
        outlined in this document. This Application adopts automated
        decision-making processes as far as necessary to enter into or perform a
        contract between User and Owner, or on the basis of the User’s explicit
        consent, where such consent is required by the law.<br />
        Automated decisions are made by technological means – mostly based on
        algorithms subject to predefined criteria – which may also be provided
        by third parties.
      </p>
      <p>
        <strong>The rationale behind the automated decision making is:</strong
        ><br />
        to enable or otherwise improve the decision-making process;<br />
        to grant Users fair and unbiased treatment based on consistent and
        uniform criteria;<br />
        to reduce the potential harm derived from human error, personal bias and
        the like which may potentially lead to discrimination or imbalance in
        the treatment of individuals etc.;<br />
        to reduce the risk of User’s failure to meet their obligation under a
        contract. To find out more about the purposes, the third-party services,
        if any, and any specific rationale for automated decisions used within
        this Application, Users can check the relevant sections in this
        document.
      </p>
      <p>
        <strong
          >Consequences of automated decision-making processes for Users and
          rights of Users subjected to it</strong
        ><br />
        As a consequence, Users subject to such processing, are entitled to
        exercise specific rights aimed at preventing or otherwise limiting the
        potential effects of the automated decisions taken.<br />
        In particular, Users have the right to:<br />
        obtain an explanation about any decision taken as a result of automated
        decision-making and express their point of view regarding this
        decision;<br />
        challenge a decision by asking the Owner to reconsider it or take a new
        decision on a different basis;<br />
        request and obtain from the Owner human intervention on such processing.
        To learn more about the User’s rights and the means to exercise them,
        the User is invited to consult the section of this document relating to
        the rights of the User.
      </p>
      <p>
        <strong
          >Personal Data collected through sources other than the User</strong
        ><br />
        The Owner of this Application may have legitimately collected Personal
        Data relating to Users without their knowledge by reusing or sourcing
        them from third parties on the grounds mentioned in the section
        specifying the legal basis of processing.Where the Owner has collected
        Personal Data in such a manner, Users may find specific information
        regarding the source within the relevant sections of this document or by
        contacting the Owner.
      </p>
      <p>
        <strong
          >Privacy Shield participation: data transfers from the EU and
          Switzerland to the United States</strong
        ><br />
        The Owner participates in and complies with the EU-U.S. Privacy Shield
        Framework and the Swiss-U.S Privacy Shield Framework as set forth by the
        U.S. Department of Commerce regarding the collection, use, and retention
        of Personal Data transferred from the European Union and Switzerland to
        the United States. The policies and rights outlined below are therefore
        equally and explicitly applicable to Users from Switzerland, except if
        stated otherwise. The Owner has certified to the Department of Commerce
        that it adheres to the Privacy Shield Principles. If there is any
        conflict between the terms in this privacy policy and the Privacy Shield
        Principles, the Privacy Shield Principles shall govern. To learn more
        about the Privacy Shield program, and to view the Owner’s certification,
        please visit
        <a href="https://www.privacyshield.gov/"
          >https://www.privacyshield.gov/</a
        >
        (or find the direct link to the certification list of Privacy Shield
        participants maintained by the Department of Commerce
        <a href="https://www.privacyshield.gov/list"
          >https://www.privacyshield.gov/list</a
        >). <strong>What does this mean for the European User?</strong> The
        Owner is responsible for all processing of Personal Data it receives
        under the Privacy Shield Framework from European Union individuals and
        commits to subject the processed Personal Data to the Privacy Shield
        Principles. This, most importantly, includes the right of individuals to
        access their personal data processed by the Owner. The Owner also
        complies with the Privacy Shield Principles for all onward transfers of
        Personal Data from the EU, which means that it remains liable in cases
        of onward transfers to third parties. With respect to Personal Data
        received or transferred pursuant to the Privacy Shield Framework, the
        Owner is subject to the investigatory and regulatory enforcement powers
        of the FTC, if not stated otherwise in this privacy policy. The Owner is
        further required to disclose Personal Data in response to lawful
        requests by public authorities, including to meet national security or
        law enforcement requirements.
        <strong>Dispute resolution under the Privacy Shield</strong> In
        compliance with the Privacy Shield Principles, the Owner commits to
        resolve complaints about its collection or use of the User’s Personal
        Data. European Union individuals with inquiries or complaints regarding
        this Privacy Shield policy should first contact the Owner at the contact
        details supplied at the beginning of this document referring to “Privacy
        Shield” and expect the complaint to be dealt with within 45 days. In
        case of failure by the Owner to provide a satisfactory or timely
        response, the User has the option of involving an independent dispute
        resolution body, free of charge. In this regard, the Owner has agreed to
        cooperate with the panel established by the EU data protection
        authorities (DPAs) and comply with the advice given by the panel with
        regard to data transferred from the EU. The User may therefore contact
        the Owner at the email address provided at the beginning of this
        document in order to be directed to the relevant DPA contacts. Under
        certain conditions – available for the User in full on the Privacy
        Shield website (<a
          href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint"
          >https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint</a
        >) – the User may invoke binding arbitration when other dispute
        resolution procedures have been exhausted.
      </p>
      <p>
        <strong
          >The Service is not directed to children under the age of 13</strong
        ><br />
        Users declare themselves to be adult according to their applicable
        legislation. Minors may use this Application only with the assistance of
        a parent or guardian. Under no circumstance persons under the age of 13
        may use this Application.
      </p>
      <p>
        <strong>The Service is directed to children under the age of 13</strong
        ><br />
        This Application is directed to children under the age of 13. It doesn’t
        require the child to disclose any more information than is reasonably
        necessary to participate in its activity.Parents may review the personal
        information about their child collected by this Application – if any –
        outlined in the rest of this policy, ask for its deletion and request
        any further collection or use to be omitted by contacting the Owner.
        Parents may also agree to the collection and use of their child’s
        information, but still not allow disclosure to third parties, unless
        disclosure is integral to the Service.
      </p>
      <p>
        <strong>Unique device identification</strong><br />
        This Application may track Users by storing a unique identifier of their
        device, for analytics purposes or for storing Users’ preferences.
      </p>
      <p>
        <strong
          >User identification via a universally unique identifier
          (UUID)</strong
        ><br />
        This Application may track Users by storing a so-called universally
        unique identifier (or short UUID) for analytics purposes or for storing
        Users’ preferences. This identifier is generated upon installation of
        this Application, it persists between Application launches and updates,
        but it is lost when the User deletes the Application. A reinstall
        generates a new UUID.
      </p>
    </div>
    <hr class="solid" />
    <div class="one_line_col">
      <p>
        <span style="font-size: 18pt;"
          ><strong>The rights of Users</strong></span
        ><br />
        Users may exercise certain rights regarding their Data processed by the
        Owner.<br />
        In particular, Users have the right to do the following:
      </p>
      <p style="padding-left: 30px;">
        <strong>-Withdraw their consent at any time.</strong> Users have the
        right to withdraw consent where they have previously given their consent
        to the processing of their Personal Data.<br />
        <strong>-Object to processing of their Data.</strong> Users have the
        right to object to the processing of their Data if the processing is
        carried out on a legal basis other than consent. Further details are
        provided in the dedicated section below.<br />
        <strong>-Access their Data.</strong> Users have the right to learn if
        Data is being processed by the Owner, obtain disclosure regarding
        certain aspects of the processing and obtain a copy of the Data
        undergoing processing.<br />
        <strong>-Verify and seek rectification.</strong> Users have the right to
        verify the accuracy of their Data and ask for it to be updated or
        corrected.<br />
        <strong>-Restrict the processing of their Data.</strong> Users have the
        right, under certain circumstances, to restrict the processing of their
        Data. In this case, the Owner will not process their Data for any
        purpose other than storing it.<br />
        <strong>-Have their Personal Data deleted or otherwise removed.</strong>
        Users have the right, under certain circumstances, to obtain the erasure
        of their Data from the Owner.<br />
        <strong
          >-Receive their Data and have it transferred to another
          controller.</strong
        >
        Users have the right to receive their Data in a structured, commonly
        used and machine readable format and, if technically feasible, to have
        it transmitted to another controller without any hindrance. This
        provision is applicable provided that the Data is processed by automated
        means and that the processing is based on the User’s consent, on a
        contract which the User is part of or on pre-contractual obligations
        thereof.<br />
        <strong>-Lodge a complaint.</strong> Users have the right to bring a
        claim before their competent data protection authority.
      </p>
      <p>
        <strong>Details about the right to object to processing</strong><br />
        Where Personal Data is processed for a public interest, in the exercise
        of an official authority vested in the Owner or for the purposes of the
        legitimate interests pursued by the Owner, Users may object to such
        processing by providing a ground related to their particular situation
        to justify the objection.<br />
        Users must know that, however, should their Personal Data be processed
        for direct marketing purposes, they can object to that processing at any
        time without providing any justification. To learn, whether the Owner is
        processing Personal Data for direct marketing purposes, Users may refer
        to the relevant sections of this document.
      </p>
      <p>
        <strong>How to exercise these rights</strong><br />
        Any requests to exercise User rights can be directed to the Owner
        through the contact details provided in this document. These requests
        can be exercised free of charge and will be addressed by the Owner as
        early as possible and always within one month.
      </p>
    </div>
    <hr class="solid" />
    <div class="wpb_wrapper">
      <p>
        <span style="font-size: 18pt;"
          ><strong
            >Additional information about Data collection and processing</strong
          ></span
        >
      </p>
      <p>
        <strong>Legal action</strong><br />
        The User’s Personal Data may be used for legal purposes by the Owner in
        Court or in the stages leading to possible legal action arising from
        improper use of this Application or the related Services.<br />
        The User declares to be aware that the Owner may be required to reveal
        personal data upon request of public authorities.
      </p>
      <p>
        <strong>Additional information about User’s Personal Data</strong><br />
        In addition to the information contained in this privacy policy, this
        Application may provide the User with additional and contextual
        information concerning particular Services or the collection and
        processing of Personal Data upon request.
      </p>
      <p>
        <strong>System logs and maintenance</strong><br />
        For operation and maintenance purposes, this Application and any
        third-party services may collect files that record interaction with this
        Application (System logs) use other Personal Data (such as the IP
        Address) for this purpose.
      </p>
      <p>
        <strong>Information not contained in this policy</strong><br />
        More details concerning the collection or processing of Personal Data
        may be requested from the Owner at any time. Please see the contact
        information at the beginning of this document.
      </p>
      <p>
        <strong>How “Do Not Track” requests are handled</strong><br />
        This Application does not support “Do Not Track” requests.<br />
        To determine whether any of the third-party services it uses honor the
        “Do Not Track” requests, please read their privacy policies.
      </p>
      <p>
        <strong>Changes to this privacy policy</strong><br />
        The Owner reserves the right to make changes to this privacy policy at
        any time by giving notice to its Users on this page and possibly within
        this Application and/or – as far as technically and legally feasible –
        sending a notice to Users via any contact information available to the
        Owner. It is strongly recommended to check this page often, referring to
        the date of the last modification listed at the bottom.<span
          class="Apple-converted-space"
          >&nbsp;</span
        >
      </p>
      <p>
        Should the changes affect processing activities performed on the basis
        of the User’s consent, the Owner shall collect new consent from the
        User, where required.
      </p>
    </div>
    <hr class="solid" />
    <div class="wpb_wrapper">
      <p>
        <span style="font-size: 18pt;"
          ><strong>Definitions and legal references</strong></span
        >
      </p>
      <p>
        <strong>Personal Data (or Data)</strong><br />
        Any information that directly, indirectly, or in connection with other
        information — including a personal identification number — allows for
        the identification or identifiability of a natural person.
      </p>
      <p>
        <strong>Usage Data</strong><br />
        Information collected automatically through this Application (or
        third-party services employed in this Application), which can include:
        the IP addresses or domain names of the computers utilized by the Users
        who use this Application, the URI addresses (Uniform Resource
        Identifier), the time of the request, the method utilized to submit the
        request to the server, the size of the file received in response, the
        numerical code indicating the status of the server’s answer (successful
        outcome, error, etc.), the country of origin, the features of the
        browser and the operating system utilized by the User, the various time
        details per visit (e.g., the time spent on each page within the
        Application) and the details about the path followed within the
        Application with special reference to the sequence of pages visited, and
        other parameters about the device operating system and/or the User’s IT
        environment.
      </p>
      <p>
        <strong>User</strong><br />
        The individual using this Application who, unless otherwise specified,
        coincides with the Data Subject.
      </p>
      <p>
        <strong>Data Subject</strong><br />
        The natural person to whom the Personal Data refers.
      </p>
      <p>
        <strong>Data Processor (or Data Supervisor)</strong><br />
        The natural or legal person, public authority, agency or other body
        which processes Personal Data on behalf of the Controller, as described
        in this privacy policy.
      </p>
      <p>
        <strong>Data Controller (or Owner)</strong><br />
        The natural or legal person, public authority, agency or other body
        which, alone or jointly with others, determines the purposes and means
        of the processing of Personal Data, including the security measures
        concerning the operation and use of this Application. The Data
        Controller, unless otherwise specified, is the Owner of this
        Application.
      </p>
      <p>
        <strong>This Application</strong><br />
        The means by which the Personal Data of the User is collected and
        processed.
      </p>
      <p>
        <strong>Service</strong><br />
        The service provided by this Application as described in the relative
        terms (if available) and on this site/application.
      </p>
      <p>
        <strong>European Union (or EU)</strong><br />
        Unless otherwise specified, all references made within this document to
        the European Union include all current member states to the European
        Union and the European Economic Area.
      </p>
      <p>
        <strong>Cookies</strong><br />
        Small sets of data stored in the User’s device.
      </p>
    </div>
    <hr class="solid" />
    <p>
      <span style="font-size: 18pt;"><strong>Legal information</strong></span
      ><br />
      This privacy statement has been prepared based on provisions of multiple
      legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General
      Data Protection Regulation).<br />
      This privacy policy relates solely to this Application, if not stated
      otherwise within this document.
    </p>

    <hr class="solid" />
    <p style="height=50px">muccanado @2021</p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  components: {},
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
header {
  height: 50px;
}
vc_sep_line {
  color: black;
}
p {
  color: black;
}
</style>
